




import { Component, Prop, Vue } from "vue-property-decorator";
import PresidentiId from "@/components/pages/presidenti/presidente/PresidentiId.vue";

@Component({
  components: {
    PresidentiId,
  },
})
export default class AdminPresidentiIdView extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  id!: string;
}
