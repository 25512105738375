




import { Component, Vue } from "vue-property-decorator";
import Log from "@/components/pages/log/Log.vue";

@Component({
  components: {
    Log,
  },
})
export default class AdminLogView extends Vue {}
