




import { Component, Vue } from "vue-property-decorator";
import ContabilitaCronologia from "@/components/pages/contabilita/cronologia/ContabilitaCronologia.vue";

@Component({
  components: {
    ContabilitaCronologia,
  },
})
export default class AdminContabilitaCronologiaView extends Vue {}
