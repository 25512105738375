




import { Component, Vue } from "vue-property-decorator";
import TipiDocumentoIdentita from "@/components/pages/tipi-documento-identita/TipiDocumentoIdentita.vue";

@Component({
  components: {
    TipiDocumentoIdentita,
  },
})
export default class AdminDocumentoIdentitaView extends Vue {}
