




import { Component, Vue } from "vue-property-decorator";
import ContrattiFuturi from "@/components/pages/contratti/futuri/ContrattiFuturi.vue";

@Component({
  components: {
    ContrattiFuturi,
  },
})
export default class AdminContrattiFuturiView extends Vue {}
