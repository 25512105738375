























import { Component, Vue } from "vue-property-decorator";

@Component
export default class AdminContabilitaView extends Vue {
  /* DATA */
  private activeTab = "";
  private tabs = [
    {
      path: "invia",
      icon: "mdi-table-arrow-up",
      label: "Invia",
    },
    {
      path: "estrai",
      icon: "mdi-table-arrow-down",
      label: "Estrai",
    },
    {
      path: "cronologia",
      icon: "mdi-table-sync",
      label: "Cronologia",
    },
  ];
}
