




import { Component, Prop, Vue } from "vue-property-decorator";
import Ospiti from "@/components/pages/ospiti/Ospiti.vue";

@Component({
  components: {
    Ospiti,
  },
})
export default class AdminOspitiView extends Vue {
  @Prop({ type: String, default: 'false' })
  createDialogOpen!: string;
}
